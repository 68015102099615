import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { isEqual } from 'lodash'
import { Button, Content, PageHeader, Tabs, Tab, TabList, TabContent, useNotification, useTo, Spinner, useModal, ButtonWithLoading } from 'scorer-ui-kit';
import { IFeatures, IUserPayload, useUserManagement } from '../hooks/useUserManagement';
import styled from 'styled-components';
import GeneralTab from '../components/users/GeneralTab';
import AdvancedTab from '../components/users/AdvancedTab';
import DeleteConfirmationModal from '../components/users/DeleteConfirmationModal';
import FeaturesTab from '../components/users/FeaturesTab';

const PageHeaderWrapper = styled.div`
  display: flex;
  margin-bottom: 65px;
  justify-content: space-between;
  max-width: 960px !important;
`;

const LeftButton = styled(Button)`
  margin-right: 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Divider = styled.div`
  height: 1px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.divider};
  margin-left: 0px;
`;

const TabContentContainer = styled.div`
  max-width: 960px !important;
`;


export interface IForm extends IUserPayload {
  role: string,
  confirmPassword: string
}

interface Params {
  userID: string
}

const initialFeatures = {
  updatePassword: true,
  userManagement: true,
  cameraEdit: true,
  cameraSnapshot: true,
  cameraConfig: true,
  cameraFiles: true,
  numberPlateDetection: true,
  intrusionDetection: true,
  faceDetection: true,
  carCount: true,
  peopleCount: true,
  relayController: true,
  eventPipeline: true,
  registerUnknown: true
};

const AddEditUser: FC = () => {
  const { userID }: Params = useParams();
  const { user, userLoading, actions: { fetchUser, updateUser, deleteUser } } = useUserManagement();
  const [form, setForm] = useState<IForm>({ username: '', role: '', is_admin: false, name: '', password: '', confirmPassword: '', feature: initialFeatures });
  const fetchedFormData = useRef<IForm>(form);
  const [isFormDataChanged, setIsFormDataChanged] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const { t } = useTranslation(['AddEditUser', 'Users', 'Common']);
  const { push } = useHistory();
  const to = useTo();
  const { sendNotification } = useNotification();
  const { createModal, setModalOpen } = useModal();
  
  useEffect(() => {
    if (userID) {
      fetchUser(parseInt(userID));
    }
  }, [fetchUser, userID]);
  
  useEffect(() => {
    if (user) {
      user && setForm(prev => {
        return {...prev, role: user.is_admin ? 'admin' : 'user', ...user};
      });
      fetchedFormData.current = {...fetchedFormData.current, role: user.is_admin ? 'admin' : 'user', ...user};
    }
  }, [user]);

  const onFormChange = useCallback((name: string, value: string|boolean|IFeatures) => {
    setForm(prev => {
      return {...prev, [name]: value};
    });
  }, []);

  useEffect(() => {
    if (userID) {
      setIsFormDataChanged(!isEqual(fetchedFormData.current, form))
    } else {
      setIsFormDataChanged(true);
    }
  }, [userID, form]);

  const validateForm = useCallback(() => {
    if (!form.username?.trim().length) {
      sendNotification({ type: 'error', message: t('userNameIsRequired') })
      return false;
    } else if (!form.name?.trim().length) {
      sendNotification({ type: 'error', message: t('fullNameIsRequired') })
      return false;
    } else {

      if (userID && (!form.password?.trim().length && !form.confirmPassword?.trim().length)) return true;

      if (!form.password?.trim().length) {
        sendNotification({ type: 'error', message: t('passwordIsRequired') })
        return false;
      } else if (form.password.length < 8) {
        sendNotification({ type: 'error', message: t('passwordLengthError') })
        return false;
      } else if (form.password !== form.confirmPassword) {
        sendNotification({ type: 'error', message: t('confirmPasswordMissMatch') })
        return false;
      }
    }
    return true;
  }, [userID, form, t, sendNotification]);

  const onSaveChange = useCallback(async () => {
    if (!validateForm()) return;
    setSaveLoading(true);
    const { role, confirmPassword, ...userData} = form;
    let payload = { ...userData, is_admin: role === 'admin' };
    
    const res: boolean = await updateUser(payload, (userID ? 'PUT' : 'POST'));

    if (res) {
      sendNotification({ type: 'success', message: userID ? t('userUpdatedSuccessfully') : t('userAddedSuccessfully') })
      push('/user-management');
    } else {
      sendNotification({ type: 'error', message: userID ? t('userUpdateFailed') : t('addingUserFailed') })
    }
    setSaveLoading(false);
  }, [userID, form, validateForm, push, updateUser, t, sendNotification]);
  
  const onDeleteConfirmation = useCallback(async () => {
    setModalOpen(false);
    const result = await deleteUser(parseInt(userID));

    if (result) {
      sendNotification({ type: 'success', message: t('userDeletedSuccessfully') })
      to('/user-management')();
    } else {
      sendNotification({ type: 'error', message: t('deletingUserFailed') })
    }
  }, [setModalOpen, deleteUser, sendNotification, t, to, userID]);

  const openDeleteConfirmModal = useCallback(() => {
    createModal({
      isCloseEnable: true,
      closeText: t('Common:close'),
      width: '580px',
      padding: false,
      customComponent: <DeleteConfirmationModal userName={form.username!} onDeleteConfirmation={onDeleteConfirmation} />,
    });
  }, [createModal, form.username, onDeleteConfirmation, t]);

  const onDelete = useCallback(() => {
    openDeleteConfirmModal();
  }, [openDeleteConfirmModal]);

  return (
    <Content>
      <PageHeaderWrapper>
        <PageHeader icon='ViewSettings' title={userID ? t('editUser') : t('addUserTitle') } areaTitle={t('Users:userManagement')} areaHref='/user-management' />
        <ButtonsContainer>
          <LeftButton design='secondary' onClick={to('/user-management')}>{t('Common:cancel')}</LeftButton>
          <ButtonWithLoading loading={saveLoading} disabled={form.role === '' || !isFormDataChanged} onClick={onSaveChange}>{userID ? t('updateUser') : t('addUser')}</ButtonWithLoading>
        </ButtonsContainer>
      </PageHeaderWrapper>

      {
        (userLoading && userID) ? (
          <Spinner size='large' styling='secondary' />
        ) :
          (

            <Tabs>
              <TabList defaultTabId='general'>
                <Tab tabFor='general'>{t('general')}</Tab>
                <Tab tabFor='features'>{t('features')}</Tab>
                {
                  userID && <Tab tabFor='advanced'>{t('advanced')}</Tab>
                }
              </TabList>

              <Divider />
              <TabContentContainer>
                <TabContent tabId='general'>
                  <GeneralTab {...{userID, form, onFormChange}} />
                </TabContent>

                <TabContent tabId='features'>
                  <FeaturesTab {...{form, onFormChange}} />
                </TabContent>

                <TabContent tabId='advanced'>
                  <AdvancedTab onDeleteCallback={onDelete} />
                </TabContent>
              </TabContentContainer>
            </Tabs>
          )
      }
    </Content>
  )
}

export default AddEditUser;