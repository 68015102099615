import { IPipelineTrigger } from '..';
import i18n from 'i18next';
const {t} = i18n;

export const Basic : IPipelineTrigger = {
  meta: t('eventRules.detectionTrigger'),
  title: t('eventRules.condition'),
  editing: false,
  subtype: 'Basic',
  data:{},
  icon: 'Critical',
}

export const NewNode : IPipelineTrigger = {
  ...Basic,
  subtype: 'NewNode',
  title: t('eventRules.newDetection'),
  data: {
  }
}

export const PersonIdentification : IPipelineTrigger = {
  ...Basic,
  icon: 'UserProfile',
  title: t('eventRules.personIdentification'),
  algorithm: 'FACE_IDENTIFICATION',
  subtype: 'PersonIdentification',
  config: [
    {
      name: 'metaType',
      fieldType: 'checkbox',
      label: t('detectionMeta.listed'),
      value: true,
      rule_value: 'LISTED',
      rule_key: 'subject_type'
    }, {
      name: 'metaType',
      fieldType: 'checkbox',
      label: t('detectionMeta.important'),
      value: true,
      rule_value: 'IMPORTANT',
      rule_key: 'subject_type'
    }, {
      name: 'metaType',
      fieldType: 'checkbox',
      label: t('detectionMeta.unknown'),
      value: true,
      rule_value: 'UNKNOWN',
      rule_key: 'subject_type'
    }
  ],
  data: {
    configFields:[]
  }
}

export const PersonCount : IPipelineTrigger = {
  ...Basic,
  icon: 'UserProfile',
  title: t('eventRules.personCount'),
  subtype: 'PersonCount',
  algorithm: 'PEOPLE_COUNTING',
  config: [],
  data: {
    configFields:[]
  }
}

export const VehicleCount : IPipelineTrigger = {
  ...Basic,
  icon: 'Vehicle',
  title: t('eventRules.vehicleCount'),
  subtype: 'VehicleCount',
  algorithm: 'CAR_COUNTING',
  config: [
    {
      name: 'metaType',
      fieldType: 'checkbox',
      label: t('detectionMeta.listed'),
      value: true,
      rule_value: 'LISTED',
      rule_key: 'subject_type'
    }, {
      name: 'metaType',
      fieldType: 'checkbox',
      label: t('detectionMeta.important'),
      value: true,
      rule_value: 'IMPORTANT',
      rule_key: 'subject_type'
    }, {
      name: 'metaType',
      fieldType: 'checkbox',
      label: t('detectionMeta.unknown'),
      value: true,
      rule_value: 'UNKNOWN',
      rule_key: 'subject_type'
    }
  ],
  data: {
    configFields:[]
  }
}

export const VehicleIdentification : IPipelineTrigger = {
  ...Basic,
  icon: 'Vehicle',
  title: t('eventRules.vehicleIdentification'),
  subtype: 'VehicleIdentification',
  algorithm: 'NUMBER_PLATE',
  config: [
    {
      name: 'metaType',
      fieldType: 'checkbox',
      label: t('detectionMeta.listed'),
      value: true,
      rule_value: 'LISTED',
      rule_key: 'subject_type'
    }, {
      name: 'metaType',
      fieldType: 'checkbox',
      label: t('detectionMeta.important'),
      value: true,
      rule_value: 'IMPORTANT',
      rule_key: 'subject_type'
    }, {
      name: 'metaType',
      fieldType: 'checkbox',
      label: t('detectionMeta.unknown'),
      value: true,
      rule_value: 'UNKNOWN',
      rule_key: 'subject_type'
    }
  ],
  data: {
    configFields:[]
  }
}

export const Intruder : IPipelineTrigger = {
  ...Basic,
  icon: 'PersonIntrusion',
  title: t('eventRules.intruder'),
  subtype: 'Intruder',
  algorithm: 'INTRUSIONS',
  config: [
    {
      name: 'metaType',
      fieldType: 'checkbox',
      label: t('eventRules.car'),
      value: true,
      rule_value: 'car',
      rule_key: 'kind'
    }, {
      name: 'metaType',
      fieldType: 'checkbox',
      label: t('eventRules.person'),
      value: true,
      rule_value: 'person',
      rule_key: 'kind'
    },
    {
      name: 'metaType',
      fieldType: 'checkbox',
      label: t('eventRules.truck'),
      value: true,
      rule_value: 'truck',
      rule_key: 'kind'
    },
    {
      name: 'metaType',
      fieldType: 'checkbox',
      label: t('eventRules.bus'),
      value: true,
      rule_value: 'bus',
      rule_key: 'kind'
    },
    {
      name: 'metaType',
      fieldType: 'checkbox',
      label: t('eventRules.motorcycle'),
      value: true,
      rule_value: 'motorcycle',
      rule_key: 'kind'
    }
  ],
  data: {
    configFields:[]
  }
}



const TriggerNodes = {
  Basic,
  NewNode,
  PersonIdentification,
  PersonCount,
  VehicleCount,
  VehicleIdentification,
  Intruder
}

export type INodeTriggerSubTypes = keyof typeof TriggerNodes;

export default TriggerNodes;