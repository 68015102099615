import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { io } from 'socket.io-client';
import { AlgorithmType } from '../hooks/useCameraConfig';
import { ICarDetection, IFaceDetection } from '../hooks/useDetections';
import { useLocalStorage } from '../hooks/useLocalStorage';

interface IAlertFilters {
  limit?: number
  active?: boolean
  alertType?: string[] | null
  location?: number[] | null
}
interface AlertsContextType {
  alerts: Alert[],
  isAlertsPaused: boolean,
  updateAlerts: React.Dispatch<React.SetStateAction<Alert[]>>
  setIsAlertsPaused: React.Dispatch<React.SetStateAction<boolean>>
  setAlertFilter: (filters: IAlertFilters) => void;
  setMuted: (value: boolean | ((val: boolean) => boolean)) => void;
}

export type AlertTypes = 'LISTED_DETECTED' | 'INTRUDER_DETECTED' | 'IMPORTANT_DETECTED' | 'UNKNOWN_DETECTED'

export interface Alert {
  datetime: string; //"11:11:11",
  alert_type: AlertTypes,
  movie_url: string; //"XXX",
  // date: string; //"2021-11-11",
  camera_id: number; //1,
  detection_id: number; //99999,
  status: 'CRITICAL' | 'WARNING' | 'OK'; //1, what is this???
  message: string;// detection_id: number; //2
  active: boolean;
  id: number;
  detection?: ICarDetection | IFaceDetection
  algorithm: AlgorithmType
  detection_table: string
  image_url: string
  record_id: string
  record_type: string
}

const defaultContext: AlertsContextType = {
  alerts: [],
  isAlertsPaused: false,
  updateAlerts: () => {
    throw new Error('Error AlertsContext used outside AlertsProvider')
  },
  setAlertFilter: () => {
    throw new Error('Error AlertsContext used outside AlertsProvider')
  },
  setMuted: () => {
    throw new Error('Error AlertsContext used outside AlertsProvider')
  },
  setIsAlertsPaused: () => {
    throw new Error('Error AlertsContext used outside AlertsProvider')
  }
};

const isDev = process.env.NODE_ENV === 'development';
const soundURL = '/alert.wav';

const socketPath = isDev ? 'ws://localhost' : '';

const AlertsContext = React.createContext(defaultContext);

const alertsList: Alert[] = [];

const AlertsProvider: React.FC = ({ children }) => {
  const [alerts, setAlerts] = useState<Alert[]>(alertsList);
  const [filters, setFilters] = useState<IAlertFilters>({limit: 20});
  const [isAlertsPaused, setIsAlertsPaused] = useState<boolean>(false);
  const [muted, setMuted] = useLocalStorage<boolean>('dashboard.alertSoundMuted', false);


  const addAlert = useCallback((alert: Alert) => {
    setAlerts((alerts) => {
      const {limit = 20, active = null, alertType = null, location = null} = filters;
      if(
        (active === null && alertType === null && location === null)||
        (active !== null && alert.active === active)||
        (alertType !== null && alertType.includes(alert.alert_type))||
        (location !== null && alert.detection && location.includes(alert.detection.camera_zone_location_id))
      ){
        !muted && playSound(soundURL);
        const a = [alert, ...alerts];
        a.length = Math.min(limit, a.length);
        return a;
      } else {
        return alerts;
      }
    });
  }, [filters, muted]);

  const dismissAlert = useCallback((alert: Alert) => {
    setAlerts((alerts) => {
      if (!alerts) return alerts;
      const index = alerts.findIndex(({ id }) => id === alert.id);
      if (index !== -1) {
        const existingAlert = alerts[index];
        return [...alerts.slice(0, index), { ...existingAlert, active: false }, ...alerts.slice(index + 1, alerts.length)]
      } else {
        return alerts;
      }
    });
  }, []);

  const setAlertFilter = useCallback((filters: IAlertFilters) => {
    setFilters((oldFilters)=> ({...oldFilters,...filters}) )
  },[]);

  useEffect(() => {
    const socket = io(`${socketPath}/alert`)
    console.debug('connect');
    socket.on('alert event', (alert: Alert) => {
      if (!isAlertsPaused) {
        addAlert(alert)
      }
    });
    socket.on('alert dismissed', (alert: Alert) =>{
      if (!isAlertsPaused) {
        dismissAlert(alert)
      }
    });

    return () => {
      console.debug('disconnect');
      socket.disconnect();
    }
  }, [isAlertsPaused, addAlert, dismissAlert]);



  const alertsContext = useMemo(() => ({ alerts, isAlertsPaused, updateAlerts: setAlerts, setAlertFilter, setIsAlertsPaused, setMuted}), [alerts, isAlertsPaused, setAlertFilter, setMuted, setIsAlertsPaused]);
  return (
    <AlertsContext.Provider value={alertsContext}>
      {children}
    </AlertsContext.Provider>
  );
}

export { AlertsContext };
export type { AlertsContextType }
export default AlertsProvider

const playSound = (url: string) => {
  const audio = new Audio(url);
  audio.play().catch(e => console.error('Sound failed to play', e.message));
}