import { FC, useState } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, useModal } from 'scorer-ui-kit';
import styled from 'styled-components';


const ModalTitle = styled.div`
  margin-bottom: 20px;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 20px;
  font-weight: 500;
  color: #727980;
`;

const ConfirmLabel = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: #7c7e7f;
`;

const CheckboxAndButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 33px;
`;

const ButtonsGroup = styled.div`
  text-align: right;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0 12px;
`;
const CheckboxLabel = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  color: #8b9196;
  cursor: pointer;
`;

const ConfirmDismissContent = styled.div`
  padding: 23px 22px 26px 22px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: hsl(200, 1%, 49%);
`;

interface IDismissAllModal {
  onDismissAll: () => void
}

const DismissAllAlertsModal:FC<IDismissAllModal> = ({onDismissAll = () => {}}) => {

  const [doNotAskAgain, setDoNotAskAgain] = useState<boolean>(false);

  const { t } = useTranslation(['Dashboard', 'Common']);
  const { setModalOpen } = useModal();

  const onChangeCheckbox = useCallback((isChecked: boolean) => {
    localStorage.setItem('doNotAskDismissAllAlerts', isChecked ? 'true' : 'false');
    setDoNotAskAgain(isChecked);
  }, []);

  const onCancel = useCallback(() => {
    if (doNotAskAgain) {
      localStorage.setItem('doNotAskDismissAllAlerts', 'false');
    }
    setModalOpen(false);
  }, [doNotAskAgain, setModalOpen]);

  return (
    <ConfirmDismissContent>
      <ModalTitle> {t('cameraTabModal.dismissModalTitle')} </ModalTitle>
      <ConfirmLabel>{t('cameraTabModal.dismissAllNote')}</ConfirmLabel>

      <CheckboxAndButtonsContainer>
        <CheckboxContainer>
          <Checkbox checked={doNotAskAgain} onChangeCallback={onChangeCheckbox} />
          <CheckboxLabel onClick={() => onChangeCheckbox(!doNotAskAgain)}>{t('cameraTabModal.doNotAskAgain')}</CheckboxLabel>
        </CheckboxContainer>
        <ButtonsGroup>
          <StyledButton design='secondary' onClick={onCancel}>
            {t('Common:cancel')}
          </StyledButton>
          <StyledButton onClick={onDismissAll}>
            {t('cameraTabModal.dismissAll')}
          </StyledButton>
        </ButtonsGroup>
      </CheckboxAndButtonsContainer>
    </ConfirmDismissContent>
  )
}

export default DismissAllAlertsModal;