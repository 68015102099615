import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ActionButtons, ConfirmationModal, DateInterval, FilterBar, IconButtonData, IFilterDatePicker, IFilterDropdownConfig, IFilterItem, IFilterResult, TypeTable, useModal, useNotification } from 'scorer-ui-kit';
import { IRowData, ITableColumnConfig, ITypeTableData } from 'scorer-ui-kit/dist/Tables';
import styled from 'styled-components';
import { IFileRecordType, IOptionsFetchCameraFiles, useCameraFiles } from '../../hooks/useCameraFiles';
import { IParams } from '../../types';
import { downloadFile, EMPTY_ROWS, formatBytes } from '../../utils';
import Pagination from '../Pagination';
import format from 'date-fns/format';
import TableCellRelativeTime from '../atoms/TableCellRelativeTime';

const Container = styled.div`
  margin: 40px 0 10px 0;
`;

const FilterWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  align-items: baseline;
  margin-bottom: 60px;
`;

interface ICameraFilesTable {

}

export interface ISelectedFilterValues {
  when?: DateInterval
  type?: IFileRecordType
  paramsLoaded?: any
  // [key: string]: string
  sortBy?: string
  sortDirection?: string;
}
const initialFilterValues : ISelectedFilterValues = {
  type: undefined,
  when: undefined,
  sortBy: 'date_time',
  sortDirection: 'desc'
}

const sizeOptions = [10, 20, 50, 100];

const CameraFilesTable: React.FC<ICameraFilesTable> = () => {
  const [rowData, setRowData] = useState<ITypeTableData>([]);
  const [pageSize, setPageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedFilterValues, setSelectedFilterValues] = useState<ISelectedFilterValues>(initialFilterValues);
  const { createModal } = useModal();
  const { cameraFiles, isLoading, totalFiles, actions: { fetchCameraFiles, deleteCameraFile } } = useCameraFiles();
  const { cameraID }: IParams = useParams();
  const { t } = useTranslation(['CameraDetails', 'Common']);
  const { sendNotification } = useNotification();
  const [refresh, setRefresh] = useState(false);


  const getApiParams = useCallback((): IOptionsFetchCameraFiles => {
    const { when, type, sortDirection = 'desc', sortBy = 'date_time' } = selectedFilterValues;

    return ({
      limit: pageSize,
      offset: pageSize * (currentPage - 1),
      startTime: (when as DateInterval)?.start,
      endTime: (when as DateInterval)?.end,
      cameraId: cameraID,
      recordType: type,
      sortBy: sortBy,
      sortDirection: sortDirection
    })
  }, [cameraID, currentPage, pageSize, selectedFilterValues]);

  useEffect(() => {
    if (cameraID) {
      fetchCameraFiles(getApiParams());
    }

  }, [cameraID, fetchCameraFiles, getApiParams, refresh])

  const pageSizeLabel = {
    htmlFor: 'select-page-size',
    text: t('Common:filter.itemsPerPage'),
    isSameRow: true,
  };

  const pageLabel = {
    htmlFor: 'select-page',
    text: t('Common:filter.page'),
    isSameRow: true,
  };

  const tableColumns: ITableColumnConfig[] = [
    {
      columnId: 'date_time',
      header: t('filesTab.recorded'),
      cellStyle: 'firstColumn',
      sortable: true,
      sortActive: true
    },
    {
      header: t('filesTab.when'),
      cellStyle: 'normalImportance',
    },
    {
      header: t('filesTab.fileType'),
      cellStyle: 'normalImportance',
    },
    {
      header: t('filesTab.fileSize'),
      cellStyle: 'normalImportance',
      showUnit: true,
    },
    {
      header: t('filesTab.actions'),
      cellStyle: 'normalImportance'
    }
  ];

  const deleteVideo = useCallback(async(param) => {
    const result = await deleteCameraFile(param);
    if (result) {
      setRefresh(previous => !previous);
      sendNotification({ type: 'success', message: t('filesTab.successDeleting') })
    } else {
      sendNotification({ type: 'error', message: t('filesTab.errorDeleting') })
    }
  }, [deleteCameraFile, sendNotification, t])


  const openConfirmationModal = useCallback((param: {recordType: string, filename: string, cameraID: string}) => {
    createModal({
      isCloseEnable: false,
      customComponent: (
        <ConfirmationModal
          title={t('Common:confirmEventDeletion')}
          message={t('Common:deleteAlertMsg')}
          leftButtonText={t('Common:cancel')}
          leftButtonDesign='secondary'
          rightButtonText={t('Common:delete')}
          rightButtonDesign='danger'
          rightButtonCallback={() => deleteVideo(param)}
        />
      )
    });
  }, [createModal, t, deleteVideo]);

  const generateConfigButtons = useCallback(({fullPath,recordType,filename}): IconButtonData[] => {
    return (
      [
        {
          icon: 'Delete',
          onClick: () => openConfirmationModal({recordType, filename, cameraID}),
        },
        {
          icon: 'DownloadVideo',
          onClick: () => downloadFile(fullPath),
        }
      ]
    )
  }, [cameraID, openConfirmationModal]);

  const generateRowData = useCallback((): ITypeTableData => {
    const data: ITypeTableData = (cameraFiles ?? []).map((file) => {
      const fullPath = file.path + file.file_name

      const { sizeNumber, sizeUnit } = formatBytes(file.size, 0);

      const row: IRowData = {
        header: {
          ...(file.record_type === 'images' ? {image: fullPath}:{mediaUrl: fullPath}),
          mediaType: file.record_type === 'images' ? 'img': 'video'
        },
        columns: [
          { text: format(new Date(file.date_time), 'yyyy/MM/dd\'-\'HH:mm'), unit: 'JST' },
          { customComponent: <TableCellRelativeTime eventTime={file.date_time.toString()} />},
          { text: t(`filesTab.${file.record_type}`) },
          { text: `${sizeNumber}` , unit: sizeUnit },
          { customComponent: <ActionButtons buttonsConfig={generateConfigButtons({filename:file.file_name, recordType: file.record_type, fullPath})} /> },
        ]
      };
      return row;
    });
    return data.length ? data : EMPTY_ROWS;
  }, [cameraFiles, generateConfigButtons, t]);

  const datePickers: IFilterDatePicker[] = [
    {
      id: 'when',
      buttonIcon: 'DateTime',
      buttonText: t('filesTab.recorded'),
      dateMode: 'interval',
      timeMode: 'interval',
      selected: undefined,
      dateTimeTextLower: t('Common:dateTo'),
      dateTimeTextUpper:  t('Common:dateFrom'),
    }
  ];

  const getTypesList = useCallback(() => {
    return [
      {
        text: t('filesTab.images'),
        value: 'images'
      },
      {
        text: t('filesTab.clips'),
        value: 'clips'
      },
      {
        text: t('filesTab.videos'),
        value: 'videos'
      }
    ];
  }, [t]);


  const dropdownConfig: IFilterDropdownConfig[] = [

    {
      id: 'type',
      buttonText: t('filesTab.fileType'),
      list: getTypesList(),
      buttonIcon: 'DevicesFolder',
      optionType: 'radio',
      selected: undefined
    }
  ]

  const onChangeFilterValue = useCallback((res: IFilterResult[]) => {
    setCurrentPage(1);
    setSelectedFilterValues( (selectedFilterValues) => {
      const selectedValues: ISelectedFilterValues = res.reduce((selectedValues: ISelectedFilterValues, { id, selected }) => {

      switch (id) {
        case 'type': selectedValues[id] = (selected as IFilterItem).value as IFileRecordType;
          break;
        case 'when': selectedValues[id] = selected as DateInterval;
          break;
      }
      return selectedValues;
    },
      { ...initialFilterValues, paramsLoaded: selectedFilterValues.paramsLoaded }
    );
    return {...selectedValues}
  })
  }, []);

  const sortCallback = useCallback((ascending: boolean, columnId: string) => {
    setSelectedFilterValues((prev) => ({ ...prev, sortDirection: ascending ? 'asc' : 'desc', sortBy: columnId }))
  }, []);

  useEffect(() => {
    setRowData(generateRowData())
  }, [generateRowData, cameraFiles])

  const onPageSizeChange = useCallback((val: string) => {
    setPageSize(parseInt(val));
    setCurrentPage(1);
  }, []);

  const onPageChange = useCallback((val: string) => {
    setCurrentPage(parseInt(val));
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil(totalFiles / pageSize));
  }, [totalFiles, pageSize]);

  return (
    <Container>
      <FilterWrapper>
        <FilterBar
          datePickersConfig={datePickers}
          dropdownsConfig={dropdownConfig}
          totalResults={rowData.length === 1 && rowData[0].columns.length === 0 ? 0 : rowData.length}
          onChangeCallback={onChangeFilterValue}
          filtersTitle={t('Common:filter.filters')}
          resultTextTemplate={t('Common:filter.showingResults') + ' ([TOTAL_RESULTS]):'}
          resultsDateFormat={'yyyy/MM/dd HH:mm'}
          clearText={t('Common:filter.clearAll')}
        />
      </FilterWrapper>
      <TypeTable
        columnConfig={tableColumns}
        rows={rowData}
        hasThumbnail
        isLoading={isLoading}
        emptyTableText={t('filesTab.noFiles')}
        loadingText={t('Common:loadingData')}
        sortCallback={sortCallback}
        defaultAscending={ selectedFilterValues.sortDirection === 'asc' ? true : false }
      />

      {rowData.length !== 0 &&
        <Pagination
          pageSizeOptions={sizeOptions}
          totalPages={totalPages}
          defaultPage={currentPage}
          defaultPageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          pageSizeLabel={pageSizeLabel}
          pageLabel={pageLabel}
        />}
    </Container>
  );
};

export default CameraFilesTable;