import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ActionButtons, ButtonWithIcon, Content, FilterBar, IconButtonData, IFilterDatePicker, IFilterDropdownConfig, IFilterItem, IFilterResult, ISearchFilter, PageHeader, TagList, TypeTable, useTo } from 'scorer-ui-kit';
import { IRowData, ITableColumnConfig, ITypeTableData } from 'scorer-ui-kit/dist/Tables';
import styled from 'styled-components';
import Pagination from '../components/Pagination';
import { INITIAL_ROWS, PAGE_SIZE_OPTIONS } from '../constants';
import { IFetchPeopleOptions, IPerson, usePeople } from '../hooks/usePeople';
import { useTags } from '../hooks/useTags';
import { dropdownHelper, getCategoTagList, getSelected, readParams, updateParams } from '../utils';
import { useCategories } from '../hooks/useCategories';
import { ISelectedFilterValues } from './KnownPeopleDetection';

const Container = styled(Content)`
  overflow: inherit;
  margin-bottom: 30px;
`;

const PageHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    max-width: 610px !important;
  }
`;

const FilterWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 48px;
`;

const TagListWrapper = styled.div`
  div {
    border: none;
  }
`;

const initialFilterValues: ISelectedFilterValues = {
  category: undefined,
  tags: undefined,
  name: undefined,
  lastDetected: undefined,
  sortDirection: 'asc',
  sortBy: 'name',
  paramsLoaded: false,
}

const People: FC = () => {

  const [rowData, setRowData] = useState<ITypeTableData>([]);
  const [pageSize, setPageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedFilterValues, setSelectedFilterValues] = useState<ISelectedFilterValues>(initialFilterValues);
  const [historyParams] = useState(useLocation().search);
  const [dropdownConfig, setDropdownConfig] = useState<IFilterDropdownConfig[]>();
  const [categoryList, setCategoryList] = useState<IFilterItem[]>([]);
  const [tagList, setTagList] = useState<IFilterItem[]>([]);

  const { people=[], totalPeople, peopleLoading, actions: { fetchPeople } } = usePeople();

  const { t } = useTranslation(['People', 'Common']);

  const { tags, actions: { fetchTags } } = useTags();
  const {catagories, actions: {fetchCategories}} = useCategories();
  const { push } = useHistory();
  const to = useTo();

  const pageSizeLabel = {
    htmlFor: 'select-page-size',
    text: t('Common:filter.itemsPerPage'),
    isSameRow: true,
  };

  const pageLabel = {
    htmlFor: 'select-page',
    text: t('Common:filter.page'),
    isSameRow: true,
  };

  const tableColumns: ITableColumnConfig[] = [
    {
      groupTitle: t('profileInformation'),
      header: t('name'),
      cellStyle: 'firstColumn',
      columnId: 'name',
      sortable: true,
      sortActive: true
    },
    {
      groupTitle: t('profileInformation'),
      header: t('categoryAndTags'),
      cellStyle: 'normalImportance'
    },
    // {
    //   groupTitle: t('lastDetection'),
    //   header: t('detected'),
    //   cellStyle: 'normalImportance',
    // },
    {
      groupTitle: t('profileInformation'),
      header: t(''),
      cellStyle: 'normalImportance'
    }
  ];

  const searchers: ISearchFilter[] = [
    {
      id: 'name',
      placeholder: t('filterByName'),
    }
  ];

  const datePickers: IFilterDatePicker[] = [
    // {
    //   id: 'lastDetected',
    //   buttonIcon: 'Date',
    //   buttonText: t('lastDetected'),
    //   dateMode: 'interval',
    //   timeMode: 'interval',
    //   // selected: getSelectedDate(selectedFilterValues.when) as DateInterval | undefined,
    // dateTimeTextLower: t('Common:dateTo'),
    // dateTimeTextUpper:  t('Common:dateFrom'),
    // }
  ];

  const getDropdownConfig = useCallback(() => {

    return ([
      {
        id: 'category',
        buttonText: t('category'),
        list: categoryList,
        buttonIcon: 'MetaCategories',
        optionType: 'radio',
        selected: getSelected(selectedFilterValues.status),
        searchResultText: `${t('Common:filter.showing')} [VISIBLE] ${t('Common:filter.of')} [TOTAL]`,
        hasOptionsFilter: true
      },
      {
        id: 'tags',
        buttonText: t('tags'),
        list: tagList,
        buttonIcon: 'MetaTags',
        optionType: 'checkbox',
        selected: getSelected(selectedFilterValues.tags),
        searchResultText: `${t('Common:filter.showing')} [VISIBLE] ${t('Common:filter.of')} [TOTAL]`,
        hasOptionsFilter: true
      }
    ]);
  }, [t, selectedFilterValues, categoryList, tagList]);

  useEffect(() => {
    setSelectedFilterValues(readParams(historyParams, initialFilterValues));
  }, [historyParams]);

  useEffect(() => {
    if (!selectedFilterValues.paramsLoaded) return;
    push({ search: updateParams(selectedFilterValues) });
  }, [push, selectedFilterValues]);

  useEffect(() => {
    setDropdownConfig(getDropdownConfig() as IFilterDropdownConfig[]);
  }, [getDropdownConfig, selectedFilterValues]);

  const sortCallback = useCallback((ascending: boolean, columnId: string) => {
    setSelectedFilterValues((prev) => ({ ...prev, sortDirection: ascending ? 'asc' : 'desc', sortBy: columnId }))
  }, []);

  const generateConfigButtons = useCallback((id: number): IconButtonData[] => {
    return (
      [
        {
          icon: '',
          onClick: () => { },
        },
        {
          icon: 'Edit',
          onClick: to(`/people/${id}/edit`),
        }
      ]
    )
  }, [to]);

  const generateRowData = useCallback((): ITypeTableData => {
    const data: ITypeTableData = people.map(({
      id,
      full_name,
      category,
      photo_url,
      tags
    }: IPerson) => {

      const row: IRowData = {
        header: {
          image: photo_url,
          mediaType: 'img',
          mediaUrl: photo_url
        },
        columns: [
          { text: `${full_name}`, href: `/people/${id}/edit` },
          {
            customComponent: (
              <TagListWrapper>
                <TagList tagsConfig={getCategoTagList(category, tags)} />
              </TagListWrapper>
            )
          },
          // { text: '2022/02/27 08:49' },
          { customComponent: <ActionButtons buttonsConfig={generateConfigButtons(id)} /> },
        ]
      };
      return row;
    });
    return data.length ? data : INITIAL_ROWS;
  }, [generateConfigButtons, people]);

  useEffect(() => {
    if (peopleLoading) return;
    setRowData(generateRowData());
  }, [generateRowData, peopleLoading, people]);

  const getApiParams = useCallback((): IFetchPeopleOptions => {

    const { sortDirection = 'asc', sortBy = 'name', name = '', category = '', tags } = selectedFilterValues;
    return ({
      name: name as string,
      category: category as string,
      tags: tags as string,
      limit: pageSize,
      offset: pageSize * (currentPage - 1),
      sortBy: sortBy as string,
      sortDirection: sortDirection as string
    });

  }, [pageSize, currentPage, selectedFilterValues]);

  useEffect(() => {
    if (!selectedFilterValues.paramsLoaded) return;
    fetchPeople(getApiParams());
  }, [fetchPeople, getApiParams, pageSize, currentPage, selectedFilterValues]);

  const onChangeFilterValue = useCallback((res: IFilterResult[]) => {
    setCurrentPage(1);
    const selectedValues: ISelectedFilterValues = res.reduce((selectedValues: ISelectedFilterValues, { id, selected }) => {

      switch (id) {
        case 'category':
          selectedValues[id] = (selected as IFilterItem).value as string;
          break;
        case 'name':
          selectedValues[id] = (selected as IFilterItem).value as string;
          break;
        default:
          selectedValues[id] = (selected as IFilterItem[]).map(({ value }) => value).join(',');
      }
      return selectedValues;
    },
      { ...initialFilterValues, paramsLoaded: selectedFilterValues.paramsLoaded }
    );
    setSelectedFilterValues({ ...selectedValues });
  }, [selectedFilterValues]);

  const onPageSizeChange = useCallback((val: string) => {
    setPageSize(parseInt(val));
    setCurrentPage(1);
  }, []);

  const onPageChange = useCallback((val: string) => {
    setCurrentPage(parseInt(val));
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil(totalPeople / pageSize));
  }, [fetchPeople, totalPeople, pageSize]);

  useEffect(() => {
    setTagList(dropdownHelper(tags, { textKey: 'tag', valueKey: 'tag' }));
  }, [tags])

  useEffect(()=>{
    setCategoryList(dropdownHelper(catagories, { textKey: 'name', valueKey: 'name' }));
  },[catagories])

  useEffect(() => {
    fetchTags();
  }, [fetchTags])

  useEffect(()=>{
    fetchCategories();
  },[fetchCategories])

  return (
    <Container>
      <PageHeaderWrapper>
        <PageHeader icon='RecognitionProfile' title={t('people')} introductionText={t('introText')} />
        <ButtonWithIcon icon='Add' position='left' onClick={to('/people/add')}>{t('addPerson')}</ButtonWithIcon>
      </PageHeaderWrapper>


      <FilterWrapper>
        {dropdownConfig &&
          <FilterBar
            searchersConfig={searchers}
            dropdownsConfig={dropdownConfig}
            datePickersConfig={datePickers}
            filtersTitle={t('Common:filter.filters') + ':'}
            resultTextTemplate={t('Common:filter.showingResults') + ' ([TOTAL_RESULTS]):'}
            resultsDateFormat={'yyyy/MM/dd HH:mm'}
            totalResults={rowData.length === 1 && rowData[0].columns.length === 0 ? 0 : rowData.length}
            onChangeCallback={onChangeFilterValue}
            clearText={t('Common:filter.clearAll')}
          />}
      </FilterWrapper>


      <TypeTable
        columnConfig={tableColumns}
        rows={rowData}
        isLoading={peopleLoading}
        emptyTableText={t('No People Available')}
        loadingText={t('Common:loadingData')}
        sortCallback={sortCallback}
        hasHeaderGroups
        hasThumbnail
        defaultAscending={ selectedFilterValues.sortDirection === 'asc' ? true : false }
      />

      {rowData.length !== 0 &&
        <Pagination
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          totalPages={totalPages}
          defaultPage={currentPage}
          defaultPageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          pageSizeLabel={pageSizeLabel}
          pageLabel={pageLabel}
        />}

    </Container>
  )

}

export default People;