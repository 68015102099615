import { formatDistance, formatRelative } from 'date-fns';
import { enUS, ja } from 'date-fns/locale';
import i18n from 'i18next';
import { DateInterval, IFilterItem, IconButtonData, ITag } from 'scorer-ui-kit';
import { ALERT_TYPES_LIST, DETECTION_TYPE_LIST, MEDIA_DIR } from './constants';
import { CameraAlertCount } from './hooks/useAlertCount';
import { Camera } from './hooks/useCameras';
import { Zone } from './hooks/useZones';
import { Location } from './hooks/useLocations';
import { ISelectedFilterValues } from './pages/KnownPeopleDetection';
import { ITypeTableData } from 'scorer-ui-kit/dist/Tables';
import { AlertTypes } from './context/AlertsContext';
import { AlgorithmType } from './hooks/useCameraConfig';
import { LocationDescriptor } from 'history';
import { ICarDetection, IFaceDetection, isFaceDetection } from './hooks/useDetections';
import { IFileSize } from './types';
const { t } = i18n;

interface IDropdownKeys {
  textKey: string;
  valueKey: string;
}
interface DefaultList {
  [key: string]: any;
}

interface IRelativeLocale {
  [key: string]: string
}

type TSelectedValue = DateInterval | string | undefined | Date | IFilterItem[] | boolean;

export const getTimeSince = (date: Date | string) => {
  if (!date) return '-';

  try {
    const lang = i18n.language === 'ja' ? ja : enUS;

    const stampDate = new Date(date);
    const eventTimestamp = stampDate.getTime();

    const result = formatDistance(
      stampDate, new Date(),
      {
        locale: lang,
        addSuffix: true,
        includeSeconds: true
      }
    );

    const diff = Number(new Date()) - eventTimestamp;
    const minute = 60 * 1000;

    if( diff < minute) {
      return t('justNow')
    }

    return lang === ja ? result : result.charAt(0).toUpperCase() + result.slice(1);
  } catch (err) {
    console.error(err);
  }
  return '-';
};

const getformatRelativeLocale = (): IRelativeLocale => {
  return {
    lastWeek: `'${t('dateTime.last')}' eeee '${t('dateTime.at')}' p`,
    yesterday: `'${t('dateTime.yesterday')} ${t('dateTime.at')}' p`,
    today: `'${t('dateTime.today')} ${t('dateTime.at')}' p`,
    tomorrow: `'tomorrow ${t('dateTime.at')}' p`,
    nextWeek: `eeee '${t('dateTime.at')}' p`,
    other: 'P p'
  };
}

export const getRelativeFormat = (date: Date | string) => {
  if (!date) return '-';

  try {
    const lang = i18n.language === 'ja' ? ja : enUS;
    const locale = {
      ...lang,
      formatRelative: (token: string) => getformatRelativeLocale()[token],
    };
    return formatRelative(new Date(date), new Date(), { locale }) || '-';
  } catch (err) {
    console.error(err);
  }

  return '-';
}

export const getFilterAlertTypes = () => {
  const list = ALERT_TYPES_LIST.map((type: string) => {

    if (type === 'UNKNOWN_DETECTED') {
      return { text: getAlertTypeLocal(type, 'NUMBER_PLATE'), value: type }
    } else {
      return { text: getAlertTypeLocal(type), value: type }
    }
  }
  );
  list.push({ text: getAlertTypeLocal('UNKNOWN_DETECTED', 'FACE_IDENTIFICATION'), value: 'UNKNOWN_DETECTED' })

  return list;
}


export const getAlertsSimpleTypesList = () => {
  return [
    {
      text: t('Common:simpleAlert.listed'),
      value: 'LISTED_DETECTED'
    },
    {
      text: t('Common:simpleAlert.important'),
      value: 'IMPORTANT_DETECTED'
    },
    {
      text: t('Common:simpleAlert.intruder'),
      value: 'INTRUDER_DETECTED'
    },
    {
      text: t('Common:simpleAlert.unknown'),
      value: 'UNKNOWN_DETECTED'
    }
  ];
}

export const getFilterAlertStatus = () => {
  return [
    {
      text: t('filter.alertStatus.active'),
      value: 'active'
    },
    {
      text: t('filter.alertStatus.dismissed'),
      value: 'inActive'
    }
  ];
}

export const getTotalAlerts = (cameras: Camera[], alertCounts: CameraAlertCount) => {
  return cameras.reduce((totalAlerts, { id }) => {
    if (alertCounts[id]) {
      return totalAlerts + alertCounts[id];
    }
    return totalAlerts
  }, 0)
}

export const getTagList = (tags: string) => {
  if (tags) {
    return tags
      .split(',')
      .map((tag: string) => ({ icon: 'MetaTags', label: tag, size: 12, color: 'dimmed' }));
  }
  return [];
};

export const getCategoTagList = (category: string, tags?: string): ITag[] => {
  let list = [];
  list.push({
    label: category ? category : '-',
    icon: 'MetaCategories',
    color: 'dimmed',
    size: 12
  });

  if (tags) {
    const tagsArray = tags.split(',').map((tag) => ({
      label: tag,
      icon: 'MetaTags',
      color: 'dimmed',
      size: 12
    }));
    list = [...list, ...tagsArray];
  } else {
    list.push({
      label: '-',
      icon: 'MetaTags',
      color: 'dimmed',
      size: 12
    });
  }

  return list;
}

export const downloadFile = async (url: string) => {
  try {
    const res = await fetch(url);
    const blob = await res.blob();

    if (blob.type === 'text/html') return;
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.setAttribute('download', url.replace(MEDIA_DIR, ''));
    a.click();
    a.remove();
  } catch (err) {
    console.error(err);
  }
}

export const getFilteredOptions = (selectedFilterValues: ISelectedFilterValues, zones: Zone[], cameras: Camera[]) => {

  const { location, zone } = selectedFilterValues;
  let filteredZones: Zone[] = [];
  let filteredCameras: Camera[] = [];
  let locationIds: string[] = [];

  if (location) {
    locationIds = (location as string)?.split(',');
    filteredZones = zones.filter(({ location_id }) => locationIds?.includes(`${location_id}`));
    if (zone === undefined) {
      filteredCameras = cameras.filter(({ location_id }) => locationIds?.includes(`${location_id}`))
      return {
        filteredZones,
        filteredCameras
      }
    }
  } else {
    filteredZones = zones;
  }

  if (zone) {
    const zoneIds = (zone as string)?.split(',');
    filteredCameras = cameras.filter(({ zone_id }) => zoneIds?.includes(`${zone_id}`));
  } else {
    filteredCameras = cameras;
  }

  return {
    filteredZones,
    filteredCameras
  }
};

export const dropdownHelper = <T extends DefaultList>(list: T[], { textKey, valueKey }: IDropdownKeys): IFilterItem[] => (
  list.map(({ [textKey]: text = '', [valueKey]: value }) => ({ text, value }))
);

export const getSelected = (value: TSelectedValue) => (value === undefined ? undefined : ({ text: '', value }));

export const getSelectedDate = (value: any) => {
  if (value) {
    const { start, end } = value;
    return ({
      start: new Date(start),
      end: new Date(end)
    })
  }
  return undefined;
}

export const readParams = <T extends {[key: string]: any} = ISelectedFilterValues,U extends keyof T = string>(historyParams: string, initialFilterValues: T) => {
  const params = new URLSearchParams(historyParams);
  const filters = params.toString()?.split('&').reduce((filterValues: T, param: string) => {
    const result: string[] = param.split('=');
    if (result[0] in initialFilterValues) {
      filterValues[result[0] as U] = result[1] ? JSON.parse(decodeURIComponent(result[1])) : undefined;
    }
    return filterValues;
  }, { ...initialFilterValues });
  return ({ ...filters, paramsLoaded: true });
};

export const updateParams = <T extends {[key: string]: any} = ISelectedFilterValues,>(selectedFilterValues: T) => {
  let params = Object.keys(selectedFilterValues).reduce((params: string, key: string) => {
    if (key === 'paramsLoaded') return params;
    params += selectedFilterValues[key] ? `${key}=${JSON.stringify(selectedFilterValues[key])}&` : '';
    return params;
  }, '');
  params = params.slice(0, -1);
  params = params ? '?' + params : '';
  return params
};

// Algorithm should be always send but making an exception for the static list of dropdown
export const getAlertTypeLocal = (value: string, algorithm?: AlgorithmType, detection?: {kind?: string, direction?: string}): string => {
  const {kind = null, direction = null} = (detection||{});

  if (value === 'IMPORTANT_DETECTED' && (algorithm === 'FACE_IDENTIFICATION')) {
    return t('Common:alertType.importantPerson')
  }

  if (value === 'LISTED_DETECTED' && (algorithm === 'FACE_IDENTIFICATION')) {
    return t('Common:alertType.listedPerson')
  }

  if (value === 'INTRUDER_DETECTED' && (kind === 'person' || algorithm === 'PEOPLE_COUNTING')) {
    if(algorithm === 'PEOPLE_COUNTING') {
      return direction === null
      ? t('Common:alertType.intruderPerson')
      : direction === 'IN' ? t('Common:alertType.peopleCountIn') : t('Common:alertType.peopleCountOut')
    } else {
      return t('Common:alertType.intruderPerson')
    }

  } else if(value === 'INTRUDER_DETECTED') {
    return t('Common:alertType.intruderVehicle')
  }

  if (value === 'IMPORTANT_DETECTED' && ((algorithm === 'CAR_COUNTING') || algorithm === 'NUMBER_PLATE')) {
    return t('Common:alertType.importantVehicle')
  }

  if (value === 'LISTED_DETECTED' && ((algorithm === 'CAR_COUNTING') || algorithm === 'NUMBER_PLATE')) {
    return t('Common:alertType.listedVehicle')
  }

  if ((value === 'UNKNOWN_DETECTED') && ((algorithm === 'CAR_COUNTING') || algorithm === 'NUMBER_PLATE')){
    return t('Common:alertType.unknownVehicle');
  }

  if ((value === 'UNKNOWN_DETECTED') && (algorithm === 'FACE_IDENTIFICATION')) {
    return t('Common:alertType.unknownPerson');
  }

  return '-'

}

export const getDetectionList = () => {
  return DETECTION_TYPE_LIST.map((value: string) => ({ text: getDetectionTypeLocal(value), value }))
}


export const getDetectionTypeLocal = (value: string): string => {
  switch (value) {
    case 'CAR_COUNTING':
      return t('Common:detectionType.carCount')

    case 'NUMBER_PLATE':
      return t('Common:detectionType.numberPlate')

    case 'FACE_IDENTIFICATION':
      return t('Common:detectionType.faceIdentification')

    case 'INTRUSIONS':
      return t('Common:detectionType.intrusions')

    case 'PEOPLE_COUNTING':
      return t('Common:detectionType.peopleCounting')

    case 'FACES':
      return t('Common:detectionType.faces')

    case 'PEOPLE':
      return t('Common:detectionType.people')

    default:
      return '-'

  }
}



// detentionTypes is coma separated this will return coma separated too with localization
export const getDetectionTypesLocal = (value?: string): string => {
  if (!value) {
    return '-';
  }

  const typesArray = value.split(',');

  const localDetectionTypes = typesArray.reduce((comaString, type) => {

    const localType = getDetectionTypeLocal(type);

    if (comaString.length === 0 && localType !== '-') {
      return getDetectionTypeLocal(type);
    }

    if (localType !== '-') {
      return `${comaString}, ${getDetectionTypeLocal(type)}`;
    }

    // Attaching nothing preventing wrong value to be "Listed Person, - , Important Vehicle"
    return comaString

  }, '');

  if (localDetectionTypes.length === 0) {
    return '-'; // It's ok to send one - to prevent empty spaces and tell the dev a value is not correct to be parse
  }

  return localDetectionTypes;
}


export const getBreadCrumbs = (locId: string, zId: string, camId: string, cameras: Camera[], zones: Zone[], locations: Location[]) => {
  const newList = [];
  const resLocation = locations.find((location: Location) => location.id === parseInt(locId));
  resLocation && newList.push({
    crumb: resLocation.name,
    layer: 2,
    locId
  });

  const resZone = zones.find((zone: Zone) => zone.id === parseInt(zId));
  resZone && newList.push({
    crumb: resZone.name,
    layer: 3,
    locId,
    zId
  });

  const resCam = cameras.find((camera: Camera) => camera.id === parseInt(camId));
  resCam && newList.push({
    crumb: resCam.name,
    layer: 3,
    locId,
    zId,
    camId
  });

  return newList

};
// https://gist.github.com/dperini/729294
export const webUrlRegex = new RegExp(
  '^' +
  // protocol identifier (optional)
  // short syntax // still required
  '(?:(?:(?:https?):)?\\/\\/)' +
  '(?:' +
  // excludes reserved space >= 224.0.0.0
  // excludes network & broadcast addresses
  // (first & last IP address of each class)
  '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
  '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
  '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
  '|' +
  // host & domain names, may end with dot
  // can be replaced by a shortest alternative
  // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
  '(?:' +
  '(?:' +
  '[a-z0-9\\u00a1-\\uffff]' +
  '[a-z0-9\\u00a1-\\uffff_-]{0,62}' +
  ')?' +
  '[a-z0-9\\u00a1-\\uffff]\\.' +
  ')+' +
  // TLD identifier name, may end with dot
  '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)' +
  ')' +
  // port number (optional)
  '(?::\\d{2,5})?' +
  // resource path (optional)
  '(?:[/?#]\\S*)?' +
  '$', 'i'
)

export const maskRegex = new RegExp(/^[0|1|9]{8}$/);

export const regexOnlyNumbers = /^[0-9]+$/;
export const regexOnly5Numbers = /^[0-9]{0,5}$/;

export const EMPTY_ROWS: ITypeTableData = [
  {
    columns: []
  }
]

export const getTypeIcon = (type: AlertTypes, algorithm: AlgorithmType, detection?: {kind?: string, direction?: string}) => {
  const {kind = null, direction = null} = (detection||{});

  switch (type) {
    case 'LISTED_DETECTED':
      if(algorithm === 'CAR_COUNTING' || algorithm === 'NUMBER_PLATE' ) {
        return 'VehicleWhitelist'
      } else {
        return 'PersonWhitelist'
      }

    case 'INTRUDER_DETECTED':
      if(kind === 'person' || algorithm === 'PEOPLE_COUNTING'){

        if(algorithm === 'PEOPLE_COUNTING') {
          return direction === null
              ? 'PersonIntrusion'
              : direction === 'IN' ? 'PersonIn' : 'PersonOut'
        } else {
          return 'PersonIntrusion'
        }

      } else {
        return 'VehicleBlacklist'
      }

    case 'IMPORTANT_DETECTED':
      if(algorithm === 'CAR_COUNTING' || algorithm === 'NUMBER_PLATE') {
        return 'VehicleBlacklist'
      } else {
        return 'PersonBlacklist'
      }

    default:
      return 'Critical'
  }
}

export const getIconAlgorithm = (algorithm: AlgorithmType) => {
  switch (algorithm) {
    case 'NUMBER_PLATE':
      return 'Vehicle'

    case 'CAR_COUNTING':
      return 'VehicleIn'

    case 'FACE_IDENTIFICATION':
      return 'PersonWhitelist'

    case 'PEOPLE_COUNTING':
      return 'PersonIn'

    case 'INTRUSIONS':
      return 'Warning'

    default:
      return 'Critical'

  }
}

export const dateTimeFormat = 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx';

interface IPush {
  (path: string, state?: unknown): void;
  (location: LocationDescriptor<unknown>): void;
}

export const getActions = (algorithm: AlgorithmType, detection: ICarDetection | IFaceDetection, image_url: string, push: IPush) => {

  const actions: IconButtonData[] = [];
  if (isFaceDetection(algorithm) && detection?.subject_type !== 'UNKNOWN') return actions;

  if (algorithm === 'NUMBER_PLATE' || algorithm === 'CAR_COUNTING') {
    const { subject_area, subject_user_type, subject_kana, subject_number } = detection as ICarDetection;
    actions.push({
      icon: 'Add',
      onClick: () => {
        push({
          pathname: '/number-plates/add',
          state: { isUnknown: true, area: subject_area, user_type: subject_user_type, kana: subject_kana, number: subject_number, image_url }
        })
      }
    });
  }

  if (algorithm === 'FACE_IDENTIFICATION') {
    actions.push({
      icon: 'Add',
      onClick: () => {
        push({
          pathname: '/people/add',
          state: { isUnknown: true, photo_url: image_url }
        })
      }
    });
  }

  return actions;
}


export const getMetaDetection = (subject_type: string) => {
  switch(subject_type) {
    case 'LISTED':
      return t('detectionMeta.listed')

    case 'IMPORTANT' :
      return t('detectionMeta.important')

    default:
      return t('detectionMeta.unknown')
  }
}

export const getMetaDetectionList = () => {
  return [
    { text: t('detectionMeta.listed'), value: 'LISTED'},
    { text: t('detectionMeta.important'), value: 'IMPORTANT'},
    { text: t('detectionMeta.unknown'), value: 'UNKNOWN'}
  ]
}

export const formatBytes = (bytes: number, decimals = 2): IFileSize => {
  if (bytes === 0) {
    return {
      sizeNumber: 0,
      sizeUnit: 'Bytes'
    }
  };

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['BYTES', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return {
    sizeNumber: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
    sizeUnit: sizes[i]
  }
}