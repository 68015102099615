import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, useModal } from 'scorer-ui-kit';
import styled from 'styled-components';


const ModalTitle = styled.div`
  margin-bottom: 20px;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 20px;
  font-weight: 500;
  color: #727980;
`;

const ConfirmLabel = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: #7c7e7f;
`;

const NameLabel = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  color: #8f8f8f;
  display: block;
`;

const Divider = styled.div<{ margin?: string }>`
  width: auto;
  height: 1px;
  margin: 19px 0px 18px 0px;
  background-color: ${({ theme }) => theme.colors.divider};
`;

const ModalDivider = styled(Divider)`
  margin: 19px 0px 5px 0px;
`;

const NoteLabel = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  color: #7c7e7f;
  margin-bottom: 10px;
`;

const DeleteNoteList = styled.ul`
  padding-left: 0;
  margin-top: 10px;
`;

const DeleteNote = styled.li`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  color: #7c7e7f;
`;

const ButtonsGroup = styled.div`
  text-align: right;
  margin-top: 28px;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

const ConfirmDeleteContent = styled.div`
  padding: 23px 40px 26px 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: hsl(200, 1%, 49%);
`;

interface IDeleteConfirmationModal {
  userName: string,
  onDeleteConfirmation: () => {}
}

const DeleteConfirmationModal: React.FC<IDeleteConfirmationModal> = ({ userName, onDeleteConfirmation }) => {
  const { t } = useTranslation(['AddEditUser', 'Users', 'Common']);
  const { setModalOpen } = useModal();

  const onCancel = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  return (
    <ConfirmDeleteContent>
      <ModalTitle> {t('AddEditUser:deleteUser')}? </ModalTitle>
      <ConfirmLabel>{t('AddEditUser:deleteConfirmText')}</ConfirmLabel>
      <NameLabel>{userName}</NameLabel>
      <Divider />
      <NoteLabel>{t('AddEditUser:pleaseNote')}</NoteLabel>
      <DeleteNoteList>
        <DeleteNote>{t('AddEditUser:deleteNote')}</DeleteNote>
      </DeleteNoteList>
      <ModalDivider />

      <ButtonsGroup>
        <StyledButton design='secondary' onClick={onCancel}>
          {t('Common:cancel')}
        </StyledButton>
        <StyledButton design='danger' onClick={onDeleteConfirmation}>
          {t('AddEditUser:confirmDeletion')}
        </StyledButton>
      </ButtonsGroup>
    </ConfirmDeleteContent>
  );
};

export default DeleteConfirmationModal;