import React, { FC, useCallback, useEffect, useState } from 'react'
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { ActionButtons, ButtonWithIcon, Content, IconButtonData, PageHeader, TypeTable, useTo } from 'scorer-ui-kit';
import { IRowData, ITableColumnConfig, ITypeTableData } from 'scorer-ui-kit/dist/Tables';
import { INITIAL_ROWS } from '../constants';
import { readParams, updateParams } from '../utils';
import { ISelectedFilterValues } from './KnownPeopleDetection';
import { IFetchUsersOptions, useUserManagement } from '../hooks/useUserManagement';
import LongTextCell from '../components/atoms/LongTextCell';
import TimeCell from '../components/atoms/TimeCell';

const Container = styled(Content)`
  overflow: inherit;
  margin-bottom: 30px;
`;

const PageHeaderWrapper = styled.div`
  display: flex;
  margin-bottom: 65px;
  justify-content: space-between;
  & > div {
    max-width: 610px !important;
  }
`;

const initialFilterValues: ISelectedFilterValues = {
  sortDirection: 'asc',
  sortBy: 'name',
  paramsLoaded: false,
}

const UserManagement:FC = () => {

  const [rowData, setRowData] = useState<ITypeTableData>([]);
  const [selectedFilterValues, setSelectedFilterValues] = useState<ISelectedFilterValues>(initialFilterValues);
  const [historyParams] = useState(useLocation().search);
  const { users, usersLoading, actions: { fetchUsers } } = useUserManagement();
  const { t } = useTranslation(['Users', 'Common']);

  const to = useTo();
  const { push } = useHistory();

  const tableColumns: ITableColumnConfig[] = [
    {
      header: t('userName'),
      cellStyle: 'firstColumn',
      columnId: 'name',
      sortable: true,
      sortActive: true
    },
    {
      header: t('type'),
      cellStyle: 'normalImportance'
    },
    {
      header: t('created'),
      cellStyle: 'normalImportance'
    },
    {
      header: t('name'),
      cellStyle: 'normalImportance',
    },
    {
      header: t('notes'),
      cellStyle: 'normalImportance'
    },
    {
      header: t('Common:edit'),
      cellStyle: 'normalImportance'
    }
  ];
  
  const generateConfigButtons = useCallback((id: number): IconButtonData[] => {
    return (
      [
        {
          icon: '',
          onClick: () => { },
        },
        {
          icon: 'Edit',
          onClick: to(`/users/${id}/edit`),
        }
      ]
    )
  }, [to]);

  const generateRowData = useCallback((): ITypeTableData => {

    const data: ITypeTableData = users.map((user) => {

      const {
        id,
        username = '-',
        created_at,
        notes,
        name = '-',
        is_admin = false,
      } = user;
      

      const row: IRowData = {
        columns: [
          { customComponent: <LongTextCell text={username} /> },
          { customComponent: <LongTextCell text={is_admin ? t('admin') : t('user')} /> },
          { customComponent: <TimeCell text={!created_at ? '-' : format(new Date().getTime(), 'yyyy/LL/dd - HH:mm')} /> },
          { customComponent: <LongTextCell text={!name ? '-' : name} /> },
          { customComponent: <LongTextCell text={!notes ? '-' : notes} /> },
          { customComponent: <ActionButtons buttonsConfig={generateConfigButtons(id)} /> }
        ]
      };
      return row;
    });
    return data.length ? data : INITIAL_ROWS;
  }, [users, t, generateConfigButtons]);

  useEffect(() => {
    if (usersLoading) return;
    setRowData(generateRowData());
  }, [users, usersLoading, generateRowData]);

  useEffect(() => {
    setSelectedFilterValues(readParams(historyParams, initialFilterValues));
  }, [historyParams]);

  useEffect(() => {
    if (!selectedFilterValues.paramsLoaded) return;
    push({ search: updateParams(selectedFilterValues) });
  }, [push, selectedFilterValues]);

  const sortCallback = useCallback((ascending: boolean, columnId: string) => {
    setSelectedFilterValues((prev) => ({ ...prev, sortDirection: ascending ? 'asc' : 'desc', sortBy: columnId }))
  }, []);
  
  const getApiParams = useCallback((): IFetchUsersOptions => {

    const { sortDirection = 'asc', sortBy = 'name', } = selectedFilterValues;
    return ({
      limit: 100,
      offset: 0,
      sortBy: sortBy as string,
      sortDirection: sortDirection as string
    });
  }, [selectedFilterValues]);

  useEffect(() => {
    if (!selectedFilterValues.paramsLoaded) return;
    fetchUsers(getApiParams());
  }, [fetchUsers, getApiParams, selectedFilterValues]);

  return (
    <Container>
      <PageHeaderWrapper>
        <PageHeader icon='ViewSettings' title={t('userManagement')} introductionText={t('introText')} areaTitle={t('settings')} />
        <ButtonWithIcon icon='Add' position='left' onClick={to('/users/add')}>{t('createUser')}</ButtonWithIcon>
      </PageHeaderWrapper>

      <TypeTable
        columnConfig={tableColumns}
        rows={rowData}
        isLoading={usersLoading}
        emptyTableText={t('noUsersAvailable')}
        loadingText={t('Common:loadingData')}
        sortCallback={sortCallback}
        hasHeaderGroups
        defaultAscending={ selectedFilterValues.sortDirection === 'asc' ? true : false }
      />
    </Container>
  )
}

export default UserManagement;